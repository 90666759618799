.active-number-input {
    width: 204px;
    background: #e6eaee;
    border-radius: 5px;
    height: 27px;
    margin: 15px auto;
}

.active-number-input input,
.active-number-input input:focus,
.active-number-input input:active,
.active-number-input input:focus-visible,
.active-number-input input:focus-within {
    border: unset !important;
    outline: unset !important;
    vertical-align: top;
    margin-top: 5px;
    margin-left: 10px;
    background: #e6eaee;
    font-size: 12px;
    width: calc(100% - 30px);
}

.arrow-container {
    display: inline-block;
    width: 20px;
    height: 27px;
}

.selected-value {
    font-size: 12px;
    vertical-align: top;
    display: inline-block;
    margin: 4px 0 0 10px;
    width: calc(100% - 30px);
}

.select-option-item {
    font-size: 12px;
}

.select-container {
    width: 100%;
    position: absolute;
    top: 28px;
    left: 0;
    background: #e6eaee;
    border-radius: 6px;
    padding: 6px 10px;
    z-index: 1;
}

.active-select-input {
    position: relative;
}

.active-radio-input {
    background: white;
}

.horizontal-space {
    display: inline-block;
    width: 10px;
}

.radio-option-item {
    display: inline-block;
    vertical-align: top;
    margin-left: 25px;
}

.radio-option-item:first-child {
    margin-left: unset;
}

.radio-option-indicator {
    width: 11px;
    height: 11px;
    background: #e6eaee;
    display: inline-block;
    border-radius: 100%;
    vertical-align: top;
    margin: 10px 12px 0 3px;
}

.radio-option-indicator.active {
    background: #0280ff;
}

.radio-option-label {
    display: inline-block;
    vertical-align: top;
    font-size: 12px;
    margin-top: 7px;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

/* Firefox */
input[type="number"] {
    -moz-appearance: textfield;
}

.output-file-type-button {
    height: 84px;
    width: 77px;
    border-radius: 6px;
    border: 1px solid #0000001a;
    padding-top: 53px;
    font-size: 12px;
    display: inline-block;
    margin-right: 20px;
    margin-bottom: 21px;
    text-align: center;
}

.output-file-type-button.active {
    border: 1px solid #2984ff;
}

.output-file-type-button-container {
    width: 390px;
    margin: 4px 0 0 10px;
}

.share-button {
    height: 84px;
    width: 77px;
    border-radius: 6px;
    border: 1px solid #0000001a;
    padding-top: 53px;
    font-size: 12px;
    display: inline-block;
    margin-right: 20px;
    margin-bottom: 21px;
    text-align: center;
}

.export-settings-popup .share-button {
    height: 52px;
    width: 48px;
    margin-right: 14px;
    padding-top: unset;
}

.group-checkbox-indicator {
    width: 18px;
    height: 18px;
    border: 1px solid #8593ad;
    border-radius: 5px;
    display: inline-block;
    vertical-align: top;
    margin: 2px 0 0 0;
}

.group-checkbox-indicator.active {
   
    border: 1px solid #0280ff;
}

.checkbox-group-container {
}

.group-checkbox-item {
    display: inline-block;
    vertical-align: top;
    margin-left: 25px;
    width: 199px;
    height: 46px;
    border-radius: 8px;
    padding: 14px 0 0 24px;
}

.checkbox-item-select-all {
    background: #f6f7f9;
}

.checkbox-group-option-label {
    font-size: 15px;
    display: inline-block;
    vertical-align: top;
    margin: 0 0 0 10px;
}

.export-result-type-button-item {
    width: 300px;
    height: 60px;
    background: rgba(134, 190, 255, 0.12);
    border: 1px solid rgba(0, 102, 255, 0.3);
    box-sizing: border-box;
    border-radius: 10px;
    display: inline-block;
    vertical-align: top;
    padding: 18px 0 0 16px;
    margin: 0 28px 21px 0;
}

.export-result-type-button-group .checkbox-group-option-label {
    margin: 0 0 0 49px;
}

.export-wizard-popup .output-file-type-button-container {
    margin: 6px 0 0 0px !important;
}

.select-analysis-button-item {
    display: inline-block;
    vertical-align: top;
    width: 126px;
    height: 74px;
    border-radius: 5px;
    margin: 0 8px 16px 8px;
    position: relative;
}

.select-analysis-button-indicator {
    width: 15px;
    height: 15px;
    border: 1px solid #ffffff;
    border-radius: 100%;
    filter: drop-shadow(1px 4px 4px rgba(77, 77, 77, 0.11));
    margin: 8px 0 0 105px;
}

.select-analysis-button-indicator.active {
    width: 15px;
    height: 15px;    
    background: #ffffff;
    border: 4px solid #2984FF;
    box-shadow: 1px 4px 4px rgba(77, 77, 77, 0.11);    
}

.select-analysis-button-label {
    color: white;
    font-weight: 500;
    font-size: 12px;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%,-50%);
    text-align: center;
}

.active-checkbox-input {
    vertical-align: top;
}

.dataset-upload-option-wrapper .radio-option-label {
    margin: 4px 10px 0 0;
    display: inline-block;
}

.dataset-upload-option-wrapper {
    display: inline-block;
    margin-right: 10px;
    vertical-align: top;
}

.dataset-settings-tab .radio-option-label {   
    margin-top: 4px;
}

.active-checkbox-input .radio-option-indicator {   
    border-radius: 33%;
    margin: 8px 12px 0 3px;
}