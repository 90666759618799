.progress-wrapper {
    position: fixed;
    z-index: 10000000000;
    width: 100%;
    justify-content: center;
    height: 100%;
    background: #ffffffb0;
}
.progress-spinner {
    position: absolute;
    top: 25%;
}